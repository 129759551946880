import React from 'react';
import {Link, withRouter} from 'react-router-dom';

function Suporte() {
    document.title = 'Suporte | ProSystem'
    return (
        <div>
            Suporte
        </div>
    );
}

export default withRouter(Suporte);