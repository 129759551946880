import React from 'react';

function ContactForm(){
    return(
        <div id="contact" className="pd--100-0">
            <div className="container">
                <div id="contactSys" className="contact--content">
                    <h2>Solicite um orçamento</h2>
                    <p>Entre em contato com nossa equipe, responderemos o quanto antes!</p>
                </div>
                <div className="row">
                    <div className="contact--form col-md-12">
                        <form method="post" action="./send.php">
                            <center><h2 className="h3">Preencha os campos abaixo</h2></center>
                            
                            <div className="contact--status"></div>
                        
                            <div className="form-group">
                                <input type="text" name="contactName" className="form-control" placeholder="Nome :" required />
                            </div>
                            
                            <div className="form-group">
                                <input type="email" name="contactEmail" className="form-control" placeholder="Endereço de Email :" required />
                            </div>
                            
                            <div className="form-group">
                                <input type="tel" name="contactTel" className="form-control" placeholder="Telefone :" />
                            </div>
                            
                            <div className="form-group">
                                <textarea name="contactMessage" className="form-control" placeholder="Mensagem :" required></textarea>
                            </div>
                            
                            <button name="send" type="submit" className="btn btn-block btn-default active">Enviar<i className="fa flm fa-send-o"></i></button>
                        </form>
                    </div>                    

                </div>
            </div>
        </div>
    );
}

export default ContactForm;