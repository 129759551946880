import React from 'react';

function Place() {
    return (
        <div id="placeBlock" className="pd--100-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src="assets/img/goiania.png" />
                    </div>
                    <div className="col-md-6 pd-top-4">
                        <h2>Onde Estamos</h2>
                        <p>A ProSystem está posicionada comercialmente no Brasil na cidade de Goiânia. Sua região de atuação não possui fronteiras devido a metodologia de desenvolvimento que proporciona flexibilidade, interações constantes com seus clientes e parceiros. Sua estrutura administrativa e operacional está centralizada na cidade de Goiânia.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Place;