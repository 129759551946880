import React from 'react';
import {withRouter} from 'react-router-dom';

function Footer() {
    return (
        <div>
            <footer id="footer" className="bg-color--codgray">

            <div className="footer--widgets pd--80-40">
                <div className="container">
                    <div className="row">

                        <div className="footer--widget col-md-4 col-sm-6">
                            <div className="footer--title h4 footer--logo">
                                <img src="assets/img/footer-logo.png" alt="" data-rjs="2" />
                            </div>
                            

                            <div className="about--widget">
                                <p>Soluções completas para atender todas as suas necessidades, com plataformas 100% online, dando liberdade para acessá-las a qualquer momento e em qualquer lugar.</p>
                                
                                {/* <ul className="about-widget--social nav navbar-nav">
                                    <li><span>Siga-nos</span></li>
                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                    <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                    <li><a href="#"><i className="fa fa-rss"></i></a></li>
                                </ul> */}
                            </div>

                        </div>

                        <div className="footer--widget col-md-4 col-sm-6">
                            <h2 className="footer--title h4">Links Úteis</h2>
                            
                            <ul>
                                <li><a href="./politicas">Políticas de Privacidade</a></li>
                                <li><a href="./sobre-nos">Conheça a ProSystem</a></li>
                                <li><a href="./#tecnology">Tecnologias que Usamos</a></li>
                                <li><a href="./#servicesBlock">Serviços que oferecemos</a></li>
                            </ul>
                        </div>

                        <div className="footer--widget col-md-4 col-sm-6">
                            <h2 className="footer--title h4">Serviços</h2>
                            
                            <ul>
                                <li><a href="/sistemas">Desenvolvimento de Sistemas</a></li>
                                <li><a href="/prodigital">ProDigital</a></li>
                                <li><a href="/propagamentos">ProPagamentos</a></li>
                                <li><a href="/console-sc">Console SC</a></li>
                            </ul>
                        </div>

                       {/*   <div className="footer--widget col-md-3 col-sm-6">
                            <h2 className="footer--title h4">E-mail Newsletter</h2>
                            
                            <p>Subscribe to get Exclusive offer &amp; updates in your E-maill</p>
                            
                            <div className="subscribe--widget" data-form-validation="true">
                                <form action="https://themelooks.us12.list-manage.com/subscribe/post?u=50e1e21235cbd751ab4c1ebaa&id=ac81d988e4" method="post" name="mc-embedded-subscribe-form" target="_blank">
                                    <div className="input-group">
                                        <input type="email" name="EMAIL" className="form-control" placeholder="Your E-mail Address" required />
                                        
                                        <span className="input-group-addon">
                                            <button type="submit" className="btn btn-primary">Subscribe</button>
                                        </span>
                                    </div>
                                </form>
                            </div>

                        </div>*/}

                    </div>
                </div>
            </div>

            <div className="footer--copyright text-center">
                <div className="container">
                    <p>&copy; Copyright 2011-2021 <a href="#">ProSystemSC</a>. Todos os direitos reservados.</p>
                </div>
            </div>

        </footer>

        <div id="backToTop">
            <a href="body" className="AnimateScrollLink"><i className="fa fa-angle-up"></i></a>
        </div>

        </div>
    );
}

export default withRouter(Footer);