import React from 'react';
import {withRouter} from 'react-router-dom';
import { HeaderCon, FeaturesCon } from '../Console';
import { Info } from '../Home';

function Console() {
    document.title = 'Console SC | ProSystem'
    return (
        <div>
            <HeaderCon />
          {/*  <FeaturesCon />*/} 
           {/*   <Info />*/} 
        </div>
    );
}

export default withRouter(Console);