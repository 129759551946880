import React from 'react';
import {withRouter} from 'react-router-dom';
import { BreadPoliticas, Term } from '../Politicas';

function Politicas() {
    document.title = 'Políticas | ProSystem'
    return (
        <div>
            <BreadPoliticas />
            <Term />
        </div>
    );
}

export default withRouter(Politicas);