import React from 'react';

function HeaderSys() {
    return (

        <div id="banner" className="bg-services system">

            <div className="banner--item" id="services">
                <div className="container">
                    <div className="row row--vc">

                        <div className="banner--img col-md-5">
                            {/* <img src="assets/img/banner-img/dedicated-slider-img.png" data-rjs="2" alt="" /> */}

                        </div>

                        <div className="banner--content col-md-7">
                            <h2 className="h1">Desenvolva seu sistema na ProSystem</h2>
                            
                            <p>Uma solução digital a PROSYSTEM SC desenvolve e oferece suporte para suas aplicações de software e/ou contatação do nosso time de desenvolvimento.</p>
                            
                            <a href="#contact" className="btn btn-default AnimateScrollLink">Solicite já um orçamento<i className="fa flm fa-long-arrow-right"></i></a>
                        </div> 

                    </div>
                </div>
            </div>

        </div>

    );
}

export default HeaderSys;