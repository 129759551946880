import React from 'react';

function Thumb(){
    return(
        <div id="thumbBlock" className="pd--100-0">
            <div className="container">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <h2>Quer conhecer mais?</h2>
                    <p>Entre em contato para que possamos entender sua necessidade e ajudarmos com a construção da sua solução!</p>
                    <a href="#" className="btn-thumb"><span className="lnr lnr-arrow-right"></span>Saiba mais</a>
                </div>
            </div>
        </div>
    );
}

export default Thumb;