import React from 'react';

function BreadPoliticas() {
    return (

        <div id="pageHeader" data-bg-img="assets/img/term-bread.jpg">
            <div className="container">

                <div className="page-header--title">
                    <h1 className="h1">Termos de Uso e Políticas</h1>
                </div>

                <div className="page-header--breadcrumb">
                    <ul className="breadcrumb">
                        <li><span className="lnr lnr-home"></span></li>
                        <li><a href="../">Início</a></li>
                        <li className="active">Políticas</li>
                    </ul>
                </div>

            </div>
        </div>

    );
}

export default BreadPoliticas;