import React from 'react';

function Info() {
    return (
        <div id="contactInfo" className="hidden-xs">
            <div className="container">

                <div className="contact-info--wrapper bg-color--theme">
                    <div className="row reset-gutter">
                        <div className="col-sm-6">

                            <div className="contact-info--item clearfix"> 
                                
                                <div className="contact-info--content"> 
                                    <h2 className="h3"><i className="fa fa-map-marker"></i> Estamos aqui</h2>
                                    <p>Avenida T7 371 Sala 2101, Goiânia-GO, Brasil</p>
                                </div>
                            </div>

                        </div>
                        
                     {  /*  <div className="col-sm-3">

                            <a href="tel:+0123456123456" className="contact-info--item clearfix">
                                <div className="contact-info--icon">
                                    <i className="fa fa-headphones"></i>
                                </div>
                                
                                <div className="contact-info--content">
                                    <h2 className="h3">+55 (62) 3637-4752</h2>
                                    <p>Atendimento em horário comercial</p>
                                </div>
                            </a>

    </div>*/}
                        
                        <div className="col-sm-6">

                            <a href="mailto:example@example.com" className="contact-info--item clearfix"> 
                                
                                <div className="contact-info--content">
                                    <h2 className="h3"> <i className="fa fa-envelope-o"></i> Nosso E-mail</h2>
                                    <p>contato@prosystemsc.com</p>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Info;