import React from 'react';

function History() {
    return (

        <div id="history">
            <div id="pageContent" className="about-page pd--100-0">
                <div className="container">
                    <div className="row">
                        <div className="middle-col col-md-6">                            
                            <img src="assets/img/section-3.png" alt="" className="" data-rjs="2" />
                        </div>
                        
                        <div className="personalizado col-md-6">
                            <h2>Personalizado</h2>
                            <p>Pensamos no melhor para você, aqui, com atendimento especializado, os sistemas são desenvolvidos para o que você precisar.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="history pd--100-0">
                <div className="container">
                    <div className="row">
                        <div className="personalizado col-md-6">
                            <h2>Nossa Empresa</h2>
                            <p>Fundada em 2011 e com sede em Goiânia-GO, a ProSystemSC alia inovação e alta tecnologia para solucionar problemas tecnológicos.
                            A empresa se destaca na área de tecnologia pela constante busca por melhores e por oferecer soluções completas referente ao desenvolvimento de sistemas para diversas finalidades.
                            Desde 2018, a empresa vem investindo massivamente no ramo de pagamentos online, desenvolvendo bancos digitais, processadoras de pagamentos e cobranças online.</p>
                        </div>
                        <div className="col-md-6">
                            <img src="assets/img/about-company.png" />
                        </div>
                        <div className="col-md-12">
                            <div className="row row--gray">
                                <div className="personalizado col3 col-md-4">
                                    <h2>Missão</h2>
                                    <p>Desenvolver soluções inovadoras e disruptivas, buscando atender todas as necessidades referentes a desenvolvimento tecnológico com qualidade, suporte e segurança.</p>
                                </div>
                                <div className="personalizado col3 col-md-4">
                                    <h2>Visão</h2>
                                    <p>Ser referência no mercado digital global, sendo reconhecidos pela excelência e atendimento completo de soluções tecnológicas.</p>
                                </div>
                                <div className="personalizado col3 col-md-4">
                                    <h2>Valores</h2>
                                    <li>Crescer com clientes satisfeitos;</li>
                                    <li>Foco no resultado;</li>
                                    <li>Melhoria contínua;</li>
                                    <li>Buscar a excelência;</li>
                                    <li>Comprometimento com os negócios;</li>
                                    <li>Paixão por tecnologias inovadoras</li>
                                </div>
                            </div>      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default History;