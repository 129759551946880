import React from 'react';

function Section2() {
    return(
        <div id="banner" className="bg-about2 about-2">
            <div id="about2">
                <div className="container">
                    <div className="row row--vc">
                        <div id="hidexs" className="col-md-7">

                        </div>
                        <div className="banner--content col-md-5">
                            <h2>Soluções Completas</h2>
                            <p>Do começo ao fim, estamos preparados para atender todas as necessidades do seu negócio, como aplicações mobile, portais web, sistemas web e migração de plataformas para nuvem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section2;