import React from 'react';
import {withRouter} from 'react-router-dom';
import { HeaderDig } from '../ProDigital';

function ProDigital() {
    document.title = 'ProDigital | ProSystem'
    return (
        <div>
            <HeaderDig />
        </div>
    );
}

export default withRouter(ProDigital);