import React from 'react';

function HeaderCon() {
    return (

        <div id="banner" className="bg-services console-sc">

            <div className="banner--item" id="services">
                <div className="container">
                    <div className="row row--vc">

                        <div className="banner--img col-md-5">
                            {/* <img src="assets/img/banner-img/dedicated-slider-img.png" data-rjs="2" alt="" /> */}

                        </div>

                        <div className="banner--content col-md-7">
                            <h2 className="h1">Use o Console SC no seu Desenvolvimento.</h2>
                            
                            <p>As ferramentas ideais para o desenvolvimento dos seus projetos, em uma plataforma responsiva e rápida. Console SC parceira do programador.</p>
                            
                            <a href="#" className="btn btn-default">Saiba Mais<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default HeaderCon;