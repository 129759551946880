import React from 'react';
import {Link, withRouter} from 'react-router-dom';

function Header() {
    return (
        <div className="wrapper">

            <header id="header">

                <div className="header--topbar bg-color--codgray">
                    <div className="container">
                        <ul className="header--social nav navbar-nav ">
                            <li><span>Siga-nos</span></li>
                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                            <li><a href="#"><i className="fa fa-youtube"></i></a></li>
                        </ul>
                        <ul className="header--contact-info nav navbar-nav navbar-right">
                           {/* <li><a href="tel:+556236374752"><i className="fa fm fa-phone-square"></i>+55 (62)3637-4752</a></li>*/}
                            <li className="hidden-xxs"><a href="mailto:contato@prosystemsc.com"><i className="fa fm fa-envelope"></i>contato@prosystemsc.com</a></li>
                        </ul>
                    </div>
                </div>

                <nav className="header--navbar navbar bg-color--alabaster" data-sticky="true">
                    <div className="container">
                        <div className="navbar-header">
                            {/* <ul className="header--nav-links cart nav navbar-nav hidden-lg hidden-md">
                                <li><a href="cart.html"><i className="fa fa-cart-arrow-down"></i></a></li>
                            </ul> */}
                            <ul className="header--nav-links client-area nav navbar-nav hidden-lg hidden-md hidden-xxs">
                                <li><a href="login.html"><i className="fa fa-sign-in"></i></a></li>
                            </ul>
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#headerNav">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a href="./" className="header--logo navbar-brand">
                                <img src="./assets/img/logo.png" alt="ProSystem Logo" data-rjs="2" />
                            </a>
                        </div>
                        <div id="headerNav" className="header--nav navbar-collapse collapse NavHoverIntent">
                            {/* <ul className="header--nav-links cart nav navbar-nav navbar-right hidden-sm hidden-xs">
                                <li><a href="cart.html"><i className="fa fa-cart-arrow-down"></i></a></li>
                            </ul> */}
                            <ul className="header--nav-links client-area nav navbar-nav navbar-right hidden-sm hidden-xs">
                                <li>
                                    <a href="#">
                                        <i className="fa fa-sign-in"></i>
                                    </a>
                                </li>
                            </ul>
                            <ul className="header--nav-links nav navbar-nav navbar-right">
                                <li><a href="./">Início</a></li>
                                <li><a href="./sobre-nos">Quem Somos</a></li>
                                <li className="dropdown">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">Serviços <span className="caret"></span></a>
                                    <ul className="dropdown-menu">
                                        <li><a href="./sistemas">Desenvolvimento de Sistemas</a></li>
                                        <li><a href="./prodigital">ProDigital</a></li>
                                        <li><a href="./propagamentos">ProPagamentos</a></li>
                                        <li><a href="./console-sc">Console SC</a></li>
                                    </ul>
                                </li>
                                {/* <li><a href="./parceiros">Parceiros</a></li> */}
                                <li><a href="https://blog.prosystemsc.com" target="_blank">Blog</a></li>
                                <li><a href="/contato">Contato</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default withRouter(Header);