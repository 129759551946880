import React from 'react';

function Slide() {
    return (
        <div id="banner">

        <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        </div>

            <div className="banner--slider owl-carousel" data-carousel-autoplay="true" data-carousel-nav="true" data-carousel-dots="true" data-carousel-dots-container="#bannerSliderNav">
                <div className="banner--item" >
                    <div className="container">
                        <div className="row row--vc">

                            <div className="banner--content slide-home col-md-6">
                                <h2 className="h1">Desenvolvimento de Sistemas</h2>
                                
                                <p>Nosso prazer é desenvolver aplicativos mobile, portais web, sistemas web, migração para plataformas na nuvem, entre vários outros.<br></br>
                                Sistemas Personalizados, para atender todas suas necessidades virtuais.</p>
                                
                                <a href="./sistemas" className="btn btn-default">Saber mais<i className="fa flm fa-long-arrow-right"></i></a >
                            </div>

                            <div className="banner--img col-md-6">
                                <img src="assets/img/sys-banner.png" alt="" data-rjs="2" />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="banner--item">
                    <div className="container">
                        <div className="row row--vc">

                            <div className="banner--img col-md-6">
                                <img src="assets/img/prodigital-banner.png" alt="" data-rjs="2" />
                            </div>

                            <div className="banner--content slide-home col-md-6">
                                <h2 className="h1">Conheça a ProDigital</h2>
                                
                               <p>Com a Prodigital você poderá realizar serviços otimizados, acessíveis e sem burocracia.<br></br>
                                Pare de pagar taxas desnecessárias aos bancos tradicionais!<br></br>
                                Abra uma conta digital e faça transferências, TED, pagamentos e solicite seu cartão pré-pago.</p>
                                
                                <a  href="./prodigital" className="btn btn-default">Saber mais<i className="fa flm fa-long-arrow-right"></i></a >
                            </div>

                        </div>
                    </div>
                </div>

                <div className="banner--item">
                    <div className="container">
                        <div className="row row--vc">

                            <div className="banner--content slide-home col-md-6">
                                <h2 className="h1">Conheça a ProPagamentos</h2>
                                
                                <p>Gerencie e realize cobranças dos seus clientes.<br></br>
                                Receba por meio de pix, boleto, cartões e links de pagamento, de forma simplificada, menos burocrático e com as menores taxas.<br></br>
                                Assuma o controle financeiro da sua empresa!</p>
                                
                                <a  href="./propagamentos" className="btn btn-default">Saber mais<i className="fa flm fa-long-arrow-right"></i></a >
                            </div>

                            <div className="banner--img col-md-6">
                                <img src="assets/img/propagamentos-banner.png" alt="" data-rjs="2" />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="banner--item">
                    <div className="container">
                        <div className="row row--vc">

                            <div className="banner--img col-md-6">
                                <img src="assets/img/console-banner.png" alt="" data-rjs="2" />
                            </div>

                            <div className="banner--content slide-home col-md-6">
                                <h2 className="h1">Conheça nosso Console SC</h2>
                                
                                <p>Para os desenvolvedores que procuram as melhores ferramentas!<br></br>
                                Tenha tudo o que precisa em um só lugar para desenvolver aplicativos web e móveis, processamento de dados, data warehousing, armazenamento e arquivamento.</p>
                                
                                <a href="./console-sc" className="btn btn-default">Saber mais<i className="fa flm fa-long-arrow-right"></i></a>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className="banner--slider-nav hidden-xs">
                <ul id="bannerSliderNav" className="nav">

                    <li className="active">
                        <div className="icon">
                            <i className="fa"><span className="lnr lnr-screen"></span></i>
                        </div>
                        
                        <div className="content bg-color--theme">
                            <h3 className="h3">Sistemas</h3>
                            
                            <p className="text-slider">Conte com a ProsystemSC para desenvolver seu software.</p>
 
                            
                        </div>
                    </li>

                    <li>
                        <div className="icon">
                            <i className="fa"><span className="lnr lnr-select"></span></i>
                        </div>
                        
                        <div className="content bg-color--theme">
                            <h3 className="h3">ProDigital</h3>
                            
                            <p className="text-slider">Realize serviços bancários, acessíveis e com menos burocracia. </p>

                            
                        </div>
                    </li>

                    <li>
                        <div className="icon">
                            <i className="fa"><span className="lnr lnr-file-empty"></span></i>
                        </div>
                        
                        <div className="content bg-color--theme">
                            <h3 className="h3">ProPagamentos</h3>
                            
                            <p className="text-slider">A solução adequada para seu negócio, Gerencie e realize cobranças. </p>

                            
                        </div>
                    </li>

                    <li>
                        <div className="icon">
                            <i className="fa"><span className="lnr lnr-layers"></span></i>
                        </div>
                        
                        <div className="content bg-color--theme">
                            <h3 className="h3">Console SC</h3>
                            
                            <p className="text-slider">Tenha tudo o que precisa em um só lugar para desenvolver sua aplicação.</p>

                            
                        </div>
                    </li>

                </ul>
            </div>

        </div>
    );
}

export default Slide;