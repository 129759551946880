import React from 'react';
import {Info} from '../Home';

function Contact(){
    return(
        <div>
            <div id="pageHeader" data-bg-img="assets/img/contact-bread.jpg">
                <div className="container">

                    <div className="page-header--title">
                        <h1 className="h1">Entre em Contato</h1>
                    </div>

                    <div className="page-header--breadcrumb">
                        <ul className="breadcrumb">
                            <li><span className="lnr lnr-home"></span></li>
                            <li><a href="../">Início</a></li>
                            <li className="active">Contato</li>
                        </ul>
                    </div>

                </div>
            </div>
            <div id="contact" className="pd--100-0">
                <div className="container">
                    <div className="contact--content">
                    <p className="text-center">Entre em contato com nossa equipe, responderemos o quanto antes!</p>
                    </div>
                    
                    <div className="row">
                        <div className="contact--form col-md-4">
                            <form method="post" action="./send.php">
                                <h2 className="h3">Como podemos ajudar?</h2>
                                
                                <div className="contact--status"></div>
                            
                                <div className="form-group">
                                    <input type="text" name="contactName" className="form-control" placeholder="Nome :" required />
                                </div>
                                
                                <div className="form-group">
                                    <input type="email" name="contactEmail" className="form-control" placeholder="Endereço de Email :" required />
                                </div>
                                
                                <div className="form-group">
                                    <input type="tel" name="contactTel" className="form-control" placeholder="Telefone :" />
                                </div>
                                
                                <div className="form-group">
                                    <textarea name="contactMessage" className="form-control" placeholder="Mensagem :" required></textarea>
                                </div>
                                
                                <button name="send" type="submit" className="btn btn-block btn-default active">Enviar<i className="fa flm fa-send-o"></i></button>
                            </form>
                        </div>

                        <div className="contact--map col-md-8">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.7369932455986!2d-49.277310384744304!3d-16.6900395500667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef14f996def83%3A0x65f5337f271c4069!2sAv.%20T-7%2C%20371%20-%202101%20-%20St.%20Oeste%2C%20Goi%C3%A2nia%20-%20GO%2C%2074140-110!5e0!3m2!1spt-BR!2sbr!4v1621375092631!5m2!1spt-BR!2sbr" width="100%" height="400px" loading="lazy" style={{border: 0}}></iframe>
                        </div>
                        

                    </div>
                </div>
            </div>
            <Info />
        </div>
    );
}

export default Contact;