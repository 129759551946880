import React from 'react';

function Cols(){
    return(
        <div id="colsAbout">
            <div className="container">
                <div className="row">
                    <div className="cols-about">
                        <img src="assets/img/hand.png" />
                        <h3>PORQUE<br></br>FAZEMOS</h3>
                        <p>Entendemos que o software é muito mais que um código bonito e funcional. Ele pode ser a nova oportunidade de negócios ou melhorias que vão ajudar pessoas e empresas. Por isso, nos preocupamos em gerar transformações reais.</p>
                    </div>
                    <div className="cols-about">
                        <img src="assets/img/piece.png" />
                        <h3>COMO<br></br>FAZEMOS</h3>
                        <p>Utilizamos metodologias que executam e entregam aquilo que é esperado. Através do nosso processo em unir negócios e design UX com tecnologia, trazemos valor ao desenvolvimento pela metodologia ágil.</p>
                    </div>
                    <div className="cols-about">
                        <img src="assets/img/gift.png" />
                        <h3>O QUE<br></br>FAZEMOS</h3>
                        <p>O que entregamos no final de tudo? Software! Mas claro que não é só isso. Aqui na ProSystem, nos preocupamos em entregar valor com propósito, seja financeiro, de eficiência, de inovação, de otimização ou de cultura.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cols;