import React from 'react';
import {withRouter} from 'react-router-dom';
import { HeaderSys, ContactForm } from '../Sistemas';
import { Info } from '../Home';

function Sistemas() {
    document.title = 'Sistemas | ProSystem'
    return (
        <div>
            <HeaderSys />
            <ContactForm />
            <Info />
        </div>
    );
}

export default withRouter(Sistemas);