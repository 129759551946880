import React from 'react';
import {withRouter} from 'react-router-dom';
import { BreadParceiros, ListaParceiros } from '../Parceiros';

function Parceiros() {
    document.title = 'Parceiros | ProSystem'
    return (
        <div>
            <BreadParceiros />
            <ListaParceiros />
        </div>
    );
}

export default withRouter(Parceiros);