import React from 'react';

function Menu(){
    return(
        <div id="fastMenu" className="pd--100-about">
            <div className="container">
                <div className="section--title">
                    <p>Navegue pelo nosso site</p>
                    <h2>Menu Rápido</h2>
                </div>
                <a href="#servicesBlock" className="AnimateScrollLink">
                    <div id="menuBlock" className="col-menu col-sm-12 col-xs-12">
                        <img src="assets/img/box.png" />
                        <h4>Nossos Serviços</h4>
                    </div>
                </a>
                <a href="/sobre-nos">
                    <div id="menuBlock" className="col-menu col-sm-12 col-xs-12">
                        <img src="assets/img/team.png" />
                        <h4>Quem Somos</h4>
                    </div>
                </a>
                <a href="#tecnology" className="AnimateScrollLink">
                    <div id="menuBlock" className="col-menu col-sm-12 col-xs-12">
                        <img src="assets/img/stack.png" />
                        <h4>Tecnologias</h4>
                    </div>
                </a>
                <a href="#">
                    <div id="menuBlock" className="col-menu col-sm-12 col-xs-12">
                        <img src="assets/img/dev.png" />
                        <h4>Central Dev</h4>
                    </div>
                </a>
                <a href="#">
                    <div id="menuBlock" className="col-menu col-sm-12 col-xs-12">
                        <img src="assets/img/consolesc.png" />
                        <h4>Console SC</h4>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Menu;