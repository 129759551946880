import React from 'react';
import {withRouter} from 'react-router-dom';
import { BannerSobre, Section2, History, Cols, Place } from '../Sobre';
import { Thumb } from '../Home';
/* import { Info } from '../Home'; */

function SobreNos() {
    document.title = 'Sobre Nós | ProSystem'
    return (
        <div>
            <BannerSobre />
            <Section2 />
            <History />
            <Cols />
            <Place />
            <Thumb />
        </div>
    );
}

export default withRouter(SobreNos);