import React from 'react';

function HeaderBol() {
    return (

        <div id="banner" className="bg-services propagamentos">

            <div className="banner--item" id="services">
                <div className="container">
                    <div className="row row--vc">

                        <div className="banner--img col-md-5">
                            {/* <img src="assets/img/banner-img/dedicated-slider-img.png" data-rjs="2" alt="" /> */}

                        </div>

                        <div className="banner--content col-md-7">
                            <h2 className="h1">Faça cobranças com a ProPagamentos.</h2>
                            
                            <p>Agora o gerenciamento do seu negócio se tornou prático e seguro. Automatize e tenha uma gestão financeira inteligente da sua empresa.</p>
                            
                            <a href="#" className="btn btn-default">Saiba Mais<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default HeaderBol;