import React from 'react';

function BannerSobre() {
    return(
        <div id="banner" className="bg-services-about about">
            <div className="banner-item" id="about">
                <div className="container">
                    <div className="row row--vc">
                        <div className="banner--content col-md-5">
                            <a className="bread-about" href="./">Início </a><span className="lnr lnr-chevron-right" style={{fontSize: '8px', fontWeight: 800, color: '#091724'}}></span><a className="bread-about-active" href="#"> Sobre Nós</a>
                            <h2 className="h1">Descomplique</h2>
                            
                            <p>Há mais de dez anos no mercado, a ProSystemSC veio pra facilitar a sua vida e te ajudar a economizar tempo e recursos!</p>
                            
                            {/* <a href="#" className="btn btn-default">Learn More<i className="fa flm fa-long-arrow-right"></i></a> */}
                        </div>
                        <div id="hidexs" className="col-md-7">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerSobre;