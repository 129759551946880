import React from 'react';

function Services(){
    return(
        <div id="servicesBlock" className="pd--100-0">
            <div className="container">
                <div className="section--title">
                    <h2>Serviços que oferecemos</h2>
                    <p>Navegue pelo nosso site</p>
                </div>
                <div className="row">
                <div className="space-100"></div>
                    <div className="col-services">
                        <img src="assets/img/system-icon.png" />
                        <h3>Sistemas</h3> 
                        <p className="text-services">Uma solução digital a PROSYSTEM SC desenvolve e oferece suporte para suas aplicações de software e/ou contatação do nosso time de desenvolvimento.</p>
                        <a href="/sistemas" className="btn-services">Saber Mais<span className="lnr lnr-arrow-right animate-arrow"></span></a>
                    </div>
                    <div className="col-services">
                        <img src="assets/img/bank-icon.png" />
                        <h3>ProDigital</h3>
                        <p className="text-services">Chega de taxas e anuidades absurdas, A forma segura e rápida de usar o seu dinheiro! Otimize seu tempo. Venha para ProDigital, abra já a sua conta!</p>
                        <a href="/prodigital" className="btn-services">Saber Mais<span className="lnr lnr-arrow-right animate-arrow"></span></a>
                    </div>
                    <div className="col-services">
                        <img src="assets/img/boleto-icon.png" />
                        <h3>ProPagamentos</h3>
                        <p className="text-services">Agora o gerenciamento do seu negócio se tornou prático e seguro. Automatize e tenha uma gestão financeira inteligente da sua empresa.</p>
                        <a href="/propagamentos" className="btn-services">Saber Mais<span className="lnr lnr-arrow-right animate-arrow"></span></a>
                    </div>
                    <div className="col-services">
                        <img src="assets/img/console-icon.png" />
                        <h3>Console SC</h3>
                        <p className="text-services">As ferramentas ideais para o desenvolvimento dos seus projetos, em uma plataforma responsiva e rápida. Console SC parceira do programador.</p>
                        <a href="/console-sc" className="btn-services">Saber Mais<span className="lnr lnr-arrow-right animate-arrow"></span></a>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default Services;