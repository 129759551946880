import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Header, Footer, Home, SobreNos, Sistemas, ProDigital, ProPagamentos, Console, Suporte, Politicas, Parceiros, Contact } from './components';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/sobre-nos" exact component={() => <SobreNos/>} />
          <Route path="/sistemas" exact component={() => <Sistemas/>} />
          <Route path="/prodigital" exact component={() => <ProDigital/>} />
          <Route path="/propagamentos" exact component={() => <ProPagamentos/>} />
          <Route path="/console-sc" exact component={() => <Console/>} />
          <Route path="/suporte" exact component={() => <Suporte/>} />
          <Route path="/politicas" exact component={() => <Politicas/>} />
          <Route path="/parceiros" exact component={() => <Parceiros/>} />
          <Route path="/contato" exact component={() => <Contact/>} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
