import React from 'react';

function Tecnology(){
    return(
        <div id="tecnology" className="pd--100-0">
            <div className="container ">
                <div className="section--title">
                    <h2>Tecnologias que utilizamos</h2>
                    <p>Nos projetos da Prosystem</p>
                </div>
                <div className="row">
                    <div className="tec-block">
                        <div className="tecnology-class">
                            <h3>Backend <span className="lnr lnr-arrow-right"></span></h3>
                            <div className="tecnology-carousel">
                                <img src="assets/img/tecs/node.png" />
                                <img src="assets/img/tecs/php.png" />
                                <img src="assets/img/tecs/laravel.png" />
                                <img src="assets/img/tecs/sql.png" />
                            </div>
                        </div>
                        <div className="tecnology-class">
                            <h3>Frontend <span className="lnr lnr-arrow-right"></span></h3>
                            <div className="tecnology-carousel">
                                <img src="assets/img/tecs/react.png" />
                                <img src="assets/img/tecs/angular.png" />
                                <img src="assets/img/tecs/ionic.png" />
                                <img src="assets/img/tecs/capacitor.png" />
                                <img src="assets/img/tecs/es5.png" />
                                <img src="assets/img/tecs/type.png" />
                            </div>
                        </div>
                        <div className="tecnology-class">
                            <h3>Cloud <span className="lnr lnr-arrow-right"></span></h3>
                            <div className="tecnology-carousel">
                                <img src="assets/img/tecs/aws.png" />
                                <img src="assets/img/tecs/ocean.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tecnology;