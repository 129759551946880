import React from 'react';

function Empresa() {
    return (
        <div id="aboutCompany" className="pd--100-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <h1 className="title--about">Uma<br></br>empresa digital</h1>
                        <p>Há mais de dez anos no mercado, a ProSystemSC veio pra facilitar a sua vida e te ajudar a economizar tempo e recursos!</p>
                        <p>Fundada em 2011 e com sede em Goiânia-GO, a ProSystemSC alia inovação e alta tecnologia para solucionar problemas tecnológicos. A empresa se destaca na área de tecnologia pela constante busca por melhores e por oferecer soluções completas referente ao desenvolvimento de sistemas para diversas finalidades. </p>
                        <div className="space-50"></div>
                        <a href="/sobre-nos" className="btn-about">Conheça a ProSystem <span className="lnr lnr-arrow-right"></span></a>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <img src="assets/img/about-company.png" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Empresa;