import React from 'react';

function HeaderDig() {
    return (

        <div id="banner" className="bg-services prodigital">

            <div className="banner--item" id="services">
                <div className="container">
                    <div className="row row--vc">

                        <div className="banner--img col-md-5">
                            {/* <img src="assets/img/banner-img/dedicated-slider-img.png" data-rjs="2" alt="" /> */}

                        </div>

                        <div className="banner--content col-md-7" id="servicesLogo">
                            <img src="assets/img/logo-services/prodigital.png" />
                            <h2 className="h1">Conheça a ProDigital, sua nova conta.</h2>
                            
                            <p>Chega de taxas e anuidades absurdas, A forma segura e rápida de usar o seu dinheiro! Otimize seu tempo. Venha para ProDigital, abra já a sua conta!</p>
                            
                            <a href="#" className="btn btn-default">Saiba mais<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default HeaderDig;