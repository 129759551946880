import React from 'react';

function ListaParceiros() {
    return (

        <div id="services" className="pd--100-0-40 affiliate--services">
            <div className="container">

                <div className="section--title">
                    <p>Parceiros da ProSystem</p>
                    <h2>Confira Nossos Parceiros</h2>
                </div>

                <div className="service--items row AdjustRow">

                    <div className="service--item col-md-4 bg--overlay text-center">
                        <div className="service--icon">
                            <img src="assets/img/sopague.png" />
                        </div>
                        
                        <div className="service--content">
                            <h2 className="h4"><a href="#">SÓ PAGUE</a></h2>
                            
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  since 1500s</p>
                            <a href="https://sopague.com/" className="btn btn-sm btn-default ">Visitar Parceiro<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>
                    </div>

                    <div className="service--item col-md-4 bg--overlay text-center">
                        <div className="service--icon">
                            <img src="assets/img/lc.png" />
                        </div>
                        
                        <div className="service--content">
                            <h2 className="h4"><a href="#">LC PAG</a></h2>
                            
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  since 1500s</p>
                            <a href="https://lcpag.com/" className="btn btn-sm btn-default ">Visitar Parceiro<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>
                    </div>

                    <div className="service--item col-md-4 bg--overlay text-center">
                        <div className="service--icon">
                            <img src="assets/img/proex.png" />
                        </div>
                        
                        <div className="service--content">
                            <h2 className="h4"><a href="#">PRO EXBIT</a></h2>
                            
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  since 1500s</p>
                            <a href="https://proexbit.com/" className="btn btn-sm btn-default ">Visitar Parceiro<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>
                    </div>

                </div>

                <div className="service--items row AdjustRow">

                    <div className="service--item col-md-4 bg--overlay text-center">
                        <div className="service--icon">
                            <img src="assets/img/promidias.png" />
                        </div>
                        
                        <div className="service--content">
                            <h2 className="h4"><a href="#">PRO MÍDIDAS</a></h2>
                            
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  since 1500s</p>
                            <a href="https://sopague.com/" className="btn btn-sm btn-default ">Visitar Parceiro<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>
                    </div>

                    <div className="service--item col-md-4 bg--overlay text-center">
                        <div className="service--icon">
                            <img src="assets/img/propagamentos.png" />
                        </div>
                        
                        <div className="service--content">
                            <h2 className="h4"><a href="#">PRO BOLETOS</a></h2>
                            
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  since 1500s</p>
                            <a href="#" className="btn btn-sm btn-default ">Visitar Parceiro<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>
                    </div>

                    <div className="service--item col-md-4 bg--overlay text-center">
                        <div className="service--icon">
                            <img src="assets/img/prodigital-black.png" />
                        </div>
                        
                        <div className="service--content">
                            <h2 className="h4"><a href="#">PRO DIGITAL</a></h2>
                            
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  since 1500s</p>
                            <a href="#" className="btn btn-sm btn-default ">Visitar Parceiro<i className="fa flm fa-long-arrow-right"></i></a>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default ListaParceiros;