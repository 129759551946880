import React from 'react';
import {withRouter} from 'react-router-dom';
import { Slide, Menu, Grid, Services, Empresa, Thumb, Tecnology, Info } from '../Home';

function Home() {
    return (
        <div>
            <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            </div>
            <Slide />
            <Menu />
            <Empresa />
            <Services />
            <Thumb />
            <Tecnology />
            <Info />
        </div>
    );
}

export default withRouter(Home);