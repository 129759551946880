import React from 'react';
import {withRouter} from 'react-router-dom';
import { HeaderBol} from '../ProPagamentos';

function ProPagamentos() {
    document.title = 'ProPagamentos | ProSystem'
    return (
        <div>
            <HeaderBol />
        </div>
    );
}

export default withRouter(ProPagamentos); 